<template>
  <div>
    <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0 d-flex column">
      <b-col cols="12" class="pl-16 pl-sm-32 py-16 overflow-hidden mb-10">
        <b-card>
          <p class="h1 text-black-100">Detail BloodBag Darah O+</p>
          <p class="h2 text-black-100">Jumlah Stok Labu Darah : <span class="h1 text-danger">50</span></p>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0 d-flex column mt-3">
      <b-col cols="12" class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden mb-10">

        <b-tabs>
          <b-tab title="Riwayat" active>
            <p class="hp-p1-body h3 mt-3">Riwayat</p>

            <div class="d-flex justify-content-between mt-3">
              <p class="hp-p1-body align-items-center">
                Semua (5)
              </p>
              <div class="row no-gutters w-15">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button variant="outline-black-50" class="border-right-0 pr-0 pl-2">
                      <i class="ri-search-line remix-icon text-primary"></i>
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input type="search" placeholder="Search" class="border-left-0"></b-form-input>
                </b-input-group>
              </div>
            </div>

            <table-riwayat-blood-bag/>
          </b-tab>
          <b-tab title="Daftar Barang">
            <p class="hp-p1-body h3 mt-3">Daftar Barang</p>

            <div class="d-flex justify-content-between mt-3">
              <p class="hp-p1-body align-items-center">
                Semua (5)
              </p>
              <div class="row no-gutters w-15">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button variant="outline-black-50" class="border-right-0 pr-0 pl-2">
                      <i class="ri-search-line remix-icon text-primary"></i>
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input type="search" placeholder="Search" class="border-left-0"></b-form-input>
                </b-input-group>
              </div>
            </div>

            <table-daftar-barang/>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BTabs,
  BTab,
  BIcon,
  BBadge,
  BButton,
  BFormInput,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import TableRiwayatBloodBag from "./components/TableRiwayatBloodBag";
import TableDaftarBarang from "./components/TableDaftarBarang";

export default {
  name: "ViewDonationStatus",
  components: {
    BCol,
    BRow,
    BIcon,
    BBadge,
    BButton,
    BTabs,
    BTab,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    TableRiwayatBloodBag,
    TableDaftarBarang
  },
};
</script>

