<template>
  <div class="col-12">
    <b-table :items="items" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" responsive="sm">
      <template v-slot:cell(status)="row">
        <b-badge :variant="row.item.statusVariant" class="w-100">
          <span class="text-center">{{ row.item.status }}</span>
        </b-badge>
      </template>
    </b-table>
  </div>
</template>

<script>
import {BTable, BBadge} from "bootstrap-vue";

export default {
  name: "TableRiwayatBloodBag",
  data() {
    return {
      sortBy: 'age',
      sortDesc: false,
      fields: [
        {key: 'status', sortable: false},
        {key: 'no', sortable: true},
        {key: 'TanggalDonor', sortable: true},
        {key: 'namaDonator', sortable: true},
        {key: 'jumlahLabu', sortable: true},
        {key: 'TanggalKadaluarsa', sortable: true},
      ],
      items: [
        {
          status: "Masuk",
          statusVariant: "success",
          no: 1,
          TanggalDonor: "12 Oktober 2023",
          namaDonator: "Edison Maryadi",
          jumlahLabu: "10",
          TanggalKadaluarsa: "21 January 2024"
        },
        {
          status: "Keluar",
          statusVariant: "danger",
          no: 1,
          TanggalDonor: "12 Oktober 2023",
          namaDonator: "Alfred D.Lassiter",
          jumlahLabu: "10",
          TanggalKadaluarsa: "21 January 2024"
        },
        {
          status: "Masuk",
          statusVariant: "success",
          no: 1,
          TanggalDonor: "12 Oktober 2023",
          namaDonator: "Langgeng Siregar",
          jumlahLabu: "10",
          TanggalKadaluarsa: "21 January 2024"
        },
      ],
    };
  },
  components: {
    BTable,
    BBadge
  }
}
</script>

<style scoped>

</style>